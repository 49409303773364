<template>
  <div>
    <!-- edit时显示 -->
    <div v-show="flang" class="add_box form1">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item label="Current operator ERP" prop="operator">
          {{ ruleForm.operator }}
        </el-form-item>
        <el-form-item label="Product Pool NO" prop="ProductPoolNO">
          {{ ruleForm.id }}
        </el-form-item>
        <el-form-item label="Product Pool Name" prop="ProductPoolName">
          {{ ruleForm.poolName }}
        </el-form-item>
        <el-form-item label="Remark" prop="remark">
          <div style="display: flex;align-items: center;">
            <el-input
              v-model.trim="ruleForm.remark"
              type="textarea"
              :rows="5"
              placeholder="Please separate the product pool notes"
            />
            <!-- <el-button
              type=""
              icon="el-icon-document"
              size="small"
              style="margin-left:20px"
              @click="createData"
            >
              Save Remark
            </el-button> -->
          </div>
        </el-form-item>
        <el-form-item v-show="flang" label="Contract NO" prop="contractNo">
          {{ ruleForm.contractNo }}
        </el-form-item>
        <el-form-item label="Add SKU" prop="">
          <el-input
            v-model.trim="ruleForm.skuId"
            type="textarea"
            :rows="5"
            placeholder="SKU is separated by ',' and can be emtered at most 10000"
            style="width:100%"
          />
        </el-form-item>
        <el-form-item label="" prop="" style="margin-top: 22px;">
          <div>
            <el-button
              size="small"
              type="primary"
              style="float:left"
              @click="createData"
            >
              Add
            </el-button>
            <!-- <el-button
              size="small"
              type="danger"
              icon="sl-icon-download"
              style="float:left"
              @click="relaySKU"
            >
              Check SKU
            </el-button> -->
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- copy时显示 -->
    <div v-show="!flang" class="add_box form1">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="180px"
        class="demo-ruleForm"
      >
        <el-form-item label="Current operator ERP" prop="name">
          {{ ruleForm.operator }}
        </el-form-item>
        <el-form-item label="Copied product pool NO" prop="contractNo">
          {{ ruleForm.id }}
        </el-form-item>
        <!-- <el-form-item label="Product Pool Name" prop="ProductpoolSKU">
          <el-tabs v-model.trim="activeName" type="card" style="width:100%">
            <el-tab-pane label="Edit the product pool name" name="first">
              <el-form-item label="" prop="">
                <el-input
                  v-model.trim="ruleForm.skuId"
                  type=""
                  placeholder="SKU is separated by ',' and can be emtered at most 30000 You can fill this by 'Fill SKU Form Excel' button below"
                  style="width:100%"
                />
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              label="Use three levels of classification"
              name="second"
            >
              <Category @change="getAllSelectLists" />
            </el-tab-pane>
          </el-tabs>
        </el-form-item> -->
        <el-form-item label="PoolName" prop="poolName">
          <el-input
            v-model.trim="ruleForm.poolName"
            type=""
            placeholder="product pool remarks up to 25 charaters"
          />
        </el-form-item>
        <el-form-item label="Remark" prop="remark">
          <el-input
            v-model.trim="ruleForm.remark"
            type=""
            placeholder="product pool remarks up to 25 charaters"
          />
        </el-form-item>
        <el-form-item label="ContractNo" prop="remark">
          <el-input
            v-model.trim="ruleForm.contractNo"
            type=""
            placeholder="product pool remarks up to 25 charaters"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$router.back()">back to list</el-button>
        <el-button
          type="primary"
          @click="createDataCopy"
        >
          Save to new product pool
        </el-button>
      </div>
    </div>
    <!-- 底部列表 -->
    <div class="panel demoWrapper serch_box">
      <el-form v-show="flang" :inline="true" class="user-search">
        <el-form-item label="Product SKU:" size="">
          <template>
            <el-input
              v-model.trim="form.skuId"
              placeholder="Pleas enter the sku"
              size=""
            />
          </template>
        </el-form-item>
        <!-- <el-form-item label="SKU Categories:" size="">
          <template>
            <el-cascader
              v-model.trim="form.SKUCategories"
              :options="options"
              clearable
              placeholder="please choose "
            />
          </template>
        </el-form-item> -->
        <el-form-item label="Status:" size="">
          <template>
            <el-select
              v-model.trim="form.state"
              size="small"
              placeholder="please choose "
            >
              <el-option
                v-for="item in optionsTitle"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-show="boxShow" label="SKU Type:" size="">
          <template>
            <el-radio-group v-model.trim="form.venderType">
              <el-radio label="0">POP goods</el-radio>
              <el-radio label="-1">Self-employed goods</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="text" size="small" @click="moreOptions">
            MoreOptions<i
              v-if="!boxShow"
              class="el-icon-arrow-right el-icon--right"
            /><i v-else class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="searchBtn"
          >
            Search
          </el-button>
          <el-button
            type=""
            icon="el-icon-download"
            size="small"
            @click="exportBtn()"
          >
            Export
          </el-button>
          <el-button
            type="warning"
            size="small"
            :disabled="btnShow"
            @click="SingleBtn()"
          >
            Single Delete
          </el-button>
          <el-button
            type="warning"
            size="small"
            :disabled="btnShows"
            @click="BatchBtn()"
          >
            Batch Delete
          </el-button>
        </el-form-item>
      </el-form>
      <div class="panel-body">
        <el-table
          :data="tableList"
          border
          fit
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
          @selection-change="handleSelectionChange"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799;">No more data</p>
          </template>
          <el-table-column type="selection" width="55" />
          <el-table-column
            label="SKU ID"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.skuId }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="SKU Name"
            width="300"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.skuName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Category"
            width=""
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ categoryLeve(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Seller"
            width=""
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.creator }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="SKU Type"
            width=""
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.venderType === -1 ||
                  scope.row.venderType === '' ||
                  scope.row.venderType === null
                  ? 'Self-employed goods'
                  : 'POP goods'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Mall Price"
            width=""
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.mallPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="SKU Status"
            width=""
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.state == 0 ? 'valid' : 'invalid' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="listQuery.pageCode"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="listQuery.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listQuery.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div style="height:25px" />
  </div>
</template>

<script>
// import Category from '../../components/categoryCascader/categoryCascader/CategorySelect1.vue';
import {
  getCategoryAndParentVOList,
  updateSKU,
  getSkuList,
  putCopy,
  deletefirst,
  down
} from '@/api/user/list';
const defaultForm = {
  contractNo: '',
  skuId: '',
  id: '',
  poolName: '',
  poolType: '',
  remark: ''
};

export default {
  name: 'Addcommodity',
  // 引入局部刷新
  inject: ['reload'],
  components: {
    // Category
  },
  data() {
    return {
      emptyImgSrc: '',
      ruleForm: Object.assign({}, defaultForm),
      dialogStatus: 'create',
      activeName: 'first',
      rules: {},
      invertLists: [],
      allSelectLists: [],
      lastSelectLists: [],
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0
      },
      tableList: [],
      // 搜索条件
      form: {
        skuId: '',
        SKUCategories: '',
        state: '',
        venderType: '',
        secCateName: '',
        firCateId: ''
      },
      options: [],
      optionsTitle: [
        {
          value: 0,
          label: 'valid'
        },
        {
          value: 1,
          label: 'invalid'
        }
      ],
      boxShow: false,
      multipleSelection: [],
      outeObject: this.$route.query,
      flang: true,
      thisApi: process.env.VUE_APP_BASE_API,
      btnShow: true,
      btnShows: true,
      ruleForms: {}
    };
  },
  watch: {
    multipleSelection: function(params) {
      if (params.length > 0 && params.length == 1) {
        this.btnShow = false;
      } else {
        this.btnShow = true;
      }
      if (params.length > 0 && params.length > 1) {
        this.btnShows = false;
      } else {
        this.btnShows = true;
      }
    }
  },
  created() {
    // getCategoryAndParentVOList().then(res => {
    //   this.getTree(res.data);
    // });
    
    if (this.outeObject.flang) {
      this.flang = true;
    } else {
      this.flang = false;
    }
    this.getFirstData(this.outeObject.id);
    this.emptyImgSrc = '';
  },
  methods: {
    categoryLeve(val) {
      if (val.thrCateName) {
        return `${val.firCateName}/${val.secCateName}/${val.thrCateName}`
      } else if(val.secCateName) {
        return `${val.firCateName}/${val.secCateName}`
      } else if (val.firCateName) {
        return `${val.firCateName}`
      } else {
        return '--'
      }
    },
    getFirstData(ids) {
      getSkuList({
        poolId: ids,
        pageNo: this.listQuery.pageCode,
        pageSize: this.listQuery.pageSize,
        venderType: this.form.venderType,
        skuId: this.form.skuId,
        state: this.form.state
      }).then(res => {
        if (res.success && res.code === 200) {
          this.ruleForms = JSON.parse(JSON.stringify(res.data.poolDTO)); // 旧的
          this.ruleForm = res.data.poolDTO;
          this.tableList = res.data.skuInfoPager.rows;
          this.listQuery.total = res.data.skuInfoPager.total;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }

      });
    },
    exportBtn() {
      var list = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const element = this.multipleSelection[i];
        list.push(element.skuId);
      }
      let p={
        poolId:this.$route.query.id,
        skuIds:list,
      }
      
      down(p).then(response => {
        this.isExcel('xlsx', 'export data', response);
      });
    },

    isExcel(type, name, data) {
      const link = document.createElement('a');
      const blob = new Blob([data]);
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${name}.` + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    SingleBtn() {
      var list = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const element = this.multipleSelection[i];
        list.push(element.skuId);
      }
      var obj = {
        poolId:this.outeObject.id,
        skuIds: list,
        contractNos: [this.outeObject.contractNo]
      };
      deletefirst(obj).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'successfully delete',
            type: 'success'
          });
          this.getFirstData(this.outeObject.id);
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          
        }
      });
    },
    BatchBtn() {
      // console.log(this.multipleSelection);
      var list = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const element = this.multipleSelection[i];
        list.push(element.skuId);
      }
      var obj = {
        poolId:this.outeObject.id,
        skuIds: list,
        contractNos: [this.outeObject.contractNo]
      };
      deletefirst(obj).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'successfully delete',
            type: 'success'
          });
          this.getFirstData(this.outeObject.id);
          
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData(this.outeObject.id);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    createDataCopy() {
      var obj = this.ruleForms;
      obj.remark = this.ruleForm.remark;
      obj.poolName = this.ruleForm.poolName;
      delete obj.id;
      putCopy(this.ruleForm.contractNo, obj).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'Description Succeeded in copying the commodity pool',
            type: 'success'
          });
          setTimeout(() => {
            this.ruleForm = Object.assign({}, defaultForm);
          }, 1000);
          
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    getTree(data) {
      data.forEach(item => {
        item.label = item.name; // 把title属性赋值给name属性
        item.value = item.id;
        item.children = item.childList;
        delete item.name; // 删除title属性
        if (item.children && item.children.length > 0) {
          item.children && this.getTree(item.children);
        } else {
          delete item.children;
        }
        // 如果有childList，递归调用
      });
      this.options = data;
    },
    // 新增
    createData() {
      let obj = {
        id: this.ruleForm.id,
        poolName: this.ruleForm.poolName,
        poolType: this.ruleForm.poolType,
        contractNo: this.ruleForm.contractNo,
        contractNos: this.ruleForm.contractNos,
        poolCateId: this.ruleForm.poolCateId,
        poolCateName: this.ruleForm.poolCateName,
        remark: this.ruleForm.remark,
        state: this.ruleForm.state,
        skuIds: this.ruleForm.skuId,
        cateId: this.ruleForm.cateId,
        enterpriseName: this.ruleForm.enterpriseName,
        skuIdList: this.ruleForm.skuIdList,
        contractNoList: this.ruleForm.contractNoList,
        createdStart: this.ruleForm.createdStart,
        createdEnd: this.ruleForm.createdEnd,
        modifiedStart: this.ruleForm.modifiedStart,
        modifiedEnd: this.ruleForm.modifiedEnd,
        contractNoListStr: this.ruleForm.contractNoListStr,
        skuIdListStr: this.ruleForm.skuIdListStr,
        idListStr: this.ruleForm.idListStr,
        idList: this.ruleForm.idList,
        poolIdSet: this.ruleForm.poolIdSet
      }
      updateSKU(obj)
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: 'Description Modifying the commodity pool succeeded',
              type: 'success'
            });
            setTimeout(() => {
              this.postForm = Object.assign({}, defaultForm);
            }, 1000);
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
           
          }
        })
        .catch(() => {
          this.$message({
            message: 'Description Failed to create a commodity pool',
            type: 'error'
          });
        });
    },
    // 获取选中项
    getAllSelectLists(data) {
      this.invertLists = data[0];
      this.allSelectLists = data[1];
      this.lastSelectLists = data[2];
    },
    // 删除contract
    relayContract() {
      this.ruleForm.contractNo = '';
    },
    // 删除sku
    relaySKU() {
      this.ruleForm.skuId = '';
      this.invertLists = [];
      this.allSelectLists = [];
      this.lastSelectLists = [];
    },
    // 下载contractNo模板
    downLoadcontractNo() {
      window.location.href =
        this.thisApi +
        '/system/user/downloadTarget?fileName=08c71d17-296f-41ed-81a3-f9461a5bcab9template.xls';
    },
    // 下载sku模板
    downLoadsku() {
      window.location.href =
        this.thisApi +
        '/system/user/downloadTarget?fileName=08c71d17-296f-41ed-81a3-f9461a5bcab9template.xls';
    },
    moreOptions() {
      this.boxShow = !this.boxShow;
    },
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData(this.outeObject.id);
    },
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData(this.outeObject.id);
    },
  }
};
</script>

<style lang="less" scoped>
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add_box {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 3vh;
}
.btn_box {
  margin-left: 36px;
}
.upload-demo > div {
  width: 203px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.fill_btn {
  width: 132px;
  height: 32px;
  background: rgba(34, 98, 255, 1);
  border-radius: 4px;
  padding: 0;
  margin-left: 16px;
}
.serch_box {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.el-button {
  // padding: 0 12px !important;
}
</style>
